import SpeechRecognition from "~/utils/speech-recognition/SpeechRecognition";

export default defineNuxtPlugin({
  name: "global",
  setup: () => {
    return {
      provide: {
        speechRecognition: SpeechRecognition,
      },
    };
  },
});
