import { EventEmitter } from "events";
import { Track } from "./track/Track";

export default class Synthesizer extends EventEmitter {
  constructor() {
    super();

    this.isSpeaking = false;
    this.lastSpokeAt = null;
    this.state = "disconnected";
    this.speechQueue = [];
    this.audioTrack = null;
    this.videoTrack = null;
    this.isSynthesizing = false;
    this.error = null;
    this.noInterrupt = false;
  }

  get isAvatar() {
    return false;
  }

  setAndEmitConnectionState(state) {
    if (state === this.state) {
      return false;
    }
    this.state = state;
    this.emit("connectionStateChanged", this.state);
    return true;
  }

  setIsSpeaking(speaking) {
    if (speaking === this.isSpeaking) {
      return;
    }
    this.isSpeaking = speaking;
    if (speaking) {
      this.lastSpokeAt = new Date();
    }
    this.emit("isSpeakingChanged", speaking);
  }

  setIsSynthesizing(synthesizing) {
    if (synthesizing === this.isSynthesizing) {
      return;
    }
    this.isSynthesizing = synthesizing;
    this.emit("isSynthesizingChanged", synthesizing);
  }

  getTrack(kind) {
    switch (kind) {
      case Track.Kind.Audio:
        return this.audioTrack;
      case Track.Kind.Video:
        return this.videoTrack;
      default:
        return null;
    }
  }

  setTrack(track) {
    this.removeTrack(track.kind);

    switch (track.kind) {
      case Track.Kind.Audio:
        this.audioTrack = track;
        break;
      case Track.Kind.Video:
        this.videoTrack = track;
        break;
      default:
        break;
    }

    this.emit("trackChanged", track);
  }

  removeTrack(kind) {
    const track = this.getTrack(kind);

    if (!track) {
      return null;
    }

    track.detach();
    track.stop();

    switch (track.kind) {
      case Track.Kind.Audio:
        this.audioTrack = null;
        break;
      case Track.Kind.Video:
        this.videoTrack = null;
        break;
    }

    this.emit("trackChanged", null);
  }
}
