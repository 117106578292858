import { TrackEvent } from "../events";
import { constraintsForOptions, unwrapConstraint } from "../utils";
import LocalTrack from "./LocalTrack";
import { Track } from "./Track";
import { detectSilence } from "./utils";

export default class LocalAudioTrack extends LocalTrack {
  constructor(mediaTrack, constraints, audioContext) {
    super(mediaTrack, Track.Kind.Audio, constraints);
    this.audioContext = audioContext;
    this.stopOnMute = false;
    this.checkForSilence();
  }

  async mute() {
    const unlock = await this.muteLock.lock();
    try {
      if (this.isMuted) {
        console.debug("Track already muted");
        return this;
      }

      // disabled special handling as it will cause BT headsets to switch communication modes
      if (this.source === Track.Source.Microphone && this.stopOnMute) {
        console.debug("stopping mic track");
        // also stop the track, so that microphone indicator is turned off
        this._mediaStreamTrack.stop();
      }
      await super.mute();
      return this;
    } finally {
      unlock();
    }
  }

  async unmute() {
    const unlock = await this.muteLock.lock();
    try {
      if (!this.isMuted) {
        console.debug("Track already unmuted");
        return this;
      }

      const deviceHasChanged =
        this._constraints.deviceId &&
        this._mediaStreamTrack.getSettings().deviceId !==
          unwrapConstraint(this._constraints.deviceId);

      if (
        this.source === Track.Source.Microphone &&
        (this.stopOnMute ||
          this._mediaStreamTrack.readyState === "ended" ||
          deviceHasChanged)
      ) {
        console.debug("reacquiring mic track");
        await this.restartTrack();
      }
      await super.unmute();

      return this;
    } finally {
      unlock();
    }
  }

  async restartTrack(options) {
    let constraints;
    if (options) {
      const streamConstraints = constraintsForOptions({ audio: options });
      if (typeof streamConstraints.audio !== "boolean") {
        constraints = streamConstraints.audio;
      }
    }
    await this.restart(constraints);
  }

  async restart(constraints) {
    const track = await super.restart(constraints);
    this.checkForSilence();
    return track;
  }

  setAudioContext(audioContext) {
    this.audioContext = audioContext;
  }

  async checkForSilence() {
    const trackIsSilent = await detectSilence(this);
    if (trackIsSilent) {
      if (!this.isMuted) {
        console.warn("silence detected on local audio track");
      }
      this.emit(TrackEvent.AudioSilenceDetected);
    }
    return trackIsSilent;
  }
}
