import { Track } from "./Track";
import { TrackEvent } from "../events";

export default class RemoteTrack extends Track {
  constructor(mediaTrack, sid, kind, receiver) {
    super(mediaTrack, kind);

    this.sid = sid;
    this.receiver = receiver;
  }

  get isLocal() {
    return false;
  }

  setMuted(muted) {
    if (this.isMuted !== muted) {
      this.isMuted = muted;
      this._mediaStreamTrack.enabled = !muted;
      this.emit(muted ? TrackEvent.Muted : TrackEvent.Unmuted, this);
    }
  }

  setMediaStream(stream) {
    // this is needed to determine when the track is finished
    this.mediaStream = stream;
    const onRemoveTrack = (event) => {
      if (event.track === this._mediaStreamTrack) {
        stream.removeEventListener("removetrack", onRemoveTrack);
        if (this.receiver && "playoutDelayHint" in this.receiver) {
          this.receiver.playoutDelayHint = undefined;
        }
        this.receiver = undefined;
        this._currentBitrate = 0;
        this.emit(TrackEvent.Ended, this);
      }
    };
    stream.addEventListener("removetrack", onRemoveTrack);
  }

  start() {
    // use `enabled` of track to enable re-use of transceiver
    super.enable();
  }

  stop() {
    // use `enabled` of track to enable re-use of transceiver
    super.disable();
  }

  async getRTCStatsReport() {
    if (!this.receiver?.getStats) {
      return;
    }
    const statsReport = await this.receiver.getStats();
    return statsReport;
  }

  setPlayoutDelay(delayInSeconds) {
    if (this.receiver) {
      if ("playoutDelayHint" in this.receiver) {
        this.receiver.playoutDelayHint = delayInSeconds;
      } else {
        console.warn("Playout delay not supported in this browser");
      }
    } else {
      console.warn("Cannot set playout delay, track already ended");
    }
  }

  getPlayoutDelay() {
    if (this.receiver) {
      if ("playoutDelayHint" in this.receiver) {
        return this.receiver.playoutDelayHint;
      } else {
        console.warn("Playout delay not supported in this browser");
      }
    } else {
      console.warn("Cannot get playout delay, track already ended");
    }
    return 0;
  }
}
