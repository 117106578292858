import Echo from "@ably/laravel-echo";
import * as Ably from "ably";

export default defineNuxtPlugin({
  name: "laravel-echo",
  setup: (nuxtApp) => {
    const client = useSanctumClient();

    window.Ably = Ably;
    window.Echo = new Echo({
      broadcaster: "ably",
      encrypted: true,
      disconnectedRetryTimeout: 15000,
      echoMessages: true,
      requestTokenFn: async (channelName, existingToken) => {
        let postData = { channel_name: channelName, token: existingToken };
        return await client("/broadcasting/auth", {
          method: "POST",
          body: postData,
        });
      },
    });

    window.Echo.connector.ably.connection.on((stateChange) => {
      if (stateChange.current === "connected") {
        console.log("connected to ably server");
      }
    });

    return {
      provide: {
        echo: window.Echo,
      },
    };
  },
});
