import { EventEmitter } from "events";
import LocalParticipant from "./participant/LocalParticipant";
import CustomMediaRecorder from "./CustomMediaRecorder";
import { Track } from "./track/Track";
import { clearTimeout, setTimeout } from "worker-timers";
import CognitiveService from "./azure/CognitiveService";

export default class Meeting extends EventEmitter {
  constructor(nuxtApp) {
    super();
    this.id = null;
    this.data = null;
    this.type = null;
    this.state = "idle";
    this.question = null;
    this.timer = null;
    this.stopAfterSpeak = false;
    this.isThinking = false;
    this.avatarConfig = null;
    this.sessionId = null;

    const { $echo, $sanctumClient } = nuxtApp;

    this.echo = $echo;
    this.client = $sanctumClient;
    this.synthesizer = null;
    this.recognizer = null;

    this.localParticipant = new LocalParticipant();
    this.mediaRecorder = new CustomMediaRecorder({
      mimeType: "video/webm;codecs=vp9,opus",
    });
  }

  setAndEmitState(state) {
    if (this.state == state) {
      return false;
    }
    this.state = state;
    this.emit("stateChanged", state);
    return true;
  }

  setIsThinking(thinking) {
    if (this.isThinking === thinking) {
      return;
    }
    this.isThinking = thinking;
    this.emit("isThinkingChanged", thinking);
  }

  setAvatarConfig(config) {
    this.avatarConfig = {
      videoCropTopLeftX: 600,
      videoCropTopLeftY: 0,
      videoCropBottomRightX: 1320,
      videoCropBottomRightY: 1080 / 2,
      talkingAvatarCharacter: "default",
      talkingAvatarStyle: "default",
      customizedAvatar: false,
      backgroundColor: "#00FF00FF",
      ...config,
    };
  }

  // Kamera ve mikrafonu aktif et
  async enableCameraAndMicrophone() {
    const [audioTrack] =
      await this.localParticipant.enableCameraAndMicrophone();
    audioTrack.stopOnMute = true;
  }

  // Ses kaydini baslat
  async startMediaRecorder() {
    const tracks = this.localParticipant.getTracks();

    if (tracks.length == 0) {
      throw new Error("No tracks found");
    }

    const uploadChunkData = async data => {
      const formData = new FormData();
      formData.append("sid", this.sessionId);
      formData.append("data", data);
      formData.append("number", this.mediaRecorder.mediaChunks.length);

      await useUploader()
        .upload(
          `/api/applicant/exam-meets/${this.id}/record`,
          formData,
          progress => {
            console.log("media upload progress", progress);
          }
        )
        .then(() => {
          console.log("media uploaded");
        })
        .catch(console.error);
    };

    this.mediaRecorder.mediaStream = new MediaStream(
      tracks.map(t => t.mediaStreamTrack)
    );

    this.mediaRecorder.onData = async data => {
      await uploadChunkData(data);
    };

    await this.mediaRecorder.start(1000 * 5);
  }

  // Events
  onIsSpeakingChanged(speaking) {
    console.log("isSpeaking", speaking);
    if (!speaking && this.stopAfterSpeak) {
      this.stopAfterSpeak = false;
      this.stop("afterSpeak");
    }

    if (this.mediaRecorder) {
      if (speaking) {
        this.mediaRecorder.pause();
      } else {
        this.mediaRecorder.resume();
      }
    }
  }

  onSpeechCompleted(speakData) {
    if (speakData.type == "greeting") {
      if (this.type === "qa") {
        this.loadQuestion();
      }
    }
  }

  onRecognizingResult({ text }) {
    if (this.synthesizer.isSpeaking) {
      try {
        if (text !== "") {
          this.synthesizer.stopSpeaking().then(skippedTextIds => {
            console.log("Skipping", skippedTextIds);
            if (skippedTextIds.length > 0) {
              this.client(
                `/api/applicant/exams/${this.data.examId}/meets/${this.id}/avatar/speak-skip`,
                {
                  method: "POST",
                  body: {
                    ids: skippedTextIds,
                  },
                }
              );
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  onRecognizedResult(result) {
    try {
      this.setIsThinking(true);

      // this.client(
      //   `/api/applicant/exams/${this.data.examId}/meets/${this.id}/answer/dialog`,
      //   {
      //     method: "POST",
      //     body: {
      //       text: result.text,
      //       pronunciation_data: result?.pronunciation,
      //     },
      //   }
      // )
      //   .finally(() => this.setIsThinking(false))
      //   .catch(console.error);

      this.channel.whisper("UserSpoken", {
        text: result.text,
        pronunciation_data: result?.pronunciation,
        user_id: this.data.userId,
        exam_meet_id: this.id,
        question_id: this.question?.id,
        exam_id: this.data.examId,
        timestamp: new Date().valueOf(),
      });
    } catch (e) {
      console.error(e);
    }
  }

  onSynthesizerConnected() {
    console.log("Synthesizer connected");
    this.client(`/api/applicant/exam-meets/${this.id}/avatar/started`, {
      method: "POST",
    }).catch(console.error);
  }

  // Avatari baslat
  async startAvatarSynthesizer() {
    this.synthesizer = CognitiveService.getAvatarSynthesizer();

    this.synthesizer.once("connected", this.onSynthesizerConnected.bind(this));

    this.synthesizer.on(
      "isSpeakingChanged",
      this.onIsSpeakingChanged.bind(this)
    );

    this.synthesizer.on("speechCompleted", this.onSpeechCompleted.bind(this));

    this.synthesizer.once("disconnected", () => {
      console.log("Synthesizer disconnected");
      this.synthesizer.off("isSpeakingChanged", this.onIsSpeakingChanged);
      this.synthesizer.off("speechCompleted", this.onSpeechCompleted);
    });

    await this.synthesizer.start(this.avatarConfig);
  }

  // Ses sentezleyiciyi baslat
  async startSpeechSynthesizer() {
    this.synthesizer = CognitiveService.getSpeechSynthesizer();

    this.synthesizer.once("connected", this.onSynthesizerConnected.bind(this));

    this.synthesizer.on(
      "isSpeakingChanged",
      this.onIsSpeakingChanged.bind(this)
    );

    this.synthesizer.on("speechCompleted", this.onSpeechCompleted.bind(this));

    this.synthesizer.once("disconnected", () => {
      console.log("SpeechSynthesizer disconnected");
      this.synthesizer.off("isSpeakingChanged", this.onIsSpeakingChanged);
      this.synthesizer.off("speechCompleted", this.onSpeechCompleted);
    });

    await this.synthesizer.start();
  }

  // Ses tanima ve konusma tanima baslat
  async startSpeechRecognizer() {
    const audioTrack = this.localParticipant.getTrack(Track.Source.Microphone);

    if (!audioTrack) {
      throw new Error("No microphone track found");
    }

    this.recognizer = CognitiveService.getSpeechRecognizer();
    this.recognizer.mediaStream = audioTrack.mediaStream;
    this.recognizer.lang = this.data.character.lang;
    this.recognizer.enablePronunciationAssessment = true;
    this.recognizer.continuous = true;

    if (this.type === "dialog") {
      this.recognizer.on("result", this.onRecognizedResult.bind(this));
      this.recognizer.on(
        "recognizingResult",
        this.onRecognizingResult.bind(this)
      );

      this.recognizer.once("sessionStopped", () => {
        this.recognizer.off("result", this.onRecognizedResult.bind(this));
        this.recognizer.off("recognizingResult", this.onRecognizingResult);
      });
    }

    await this.recognizer.start();
  }

  // Soket baglantisini kur
  async connectSocket() {
    const { userId } = this.data;

    this.channel = this.echo.private(`Meet.${userId}`);

    this.channel.listen("AvatarSpeak", e => {
      try {
        if (!this.recognizer.isRecognizing) {
          this.synthesizer.speak(e);
        }
      } catch (e) {
        console.error(e);
      }
    });

    this.channel.listen("MeetingStop", e => {
      if (e.action === "afterSpeak") {
        this.stopAfterSpeak = true;
      } else {
        this.stop("event");
      }
    });
  }

  // Meeti baslat
  async start(meetId) {
    console.log("[Meet] Starting", meetId);

    try {
      this.setAndEmitState("loading");

      // Kamera ve mikrafonu aktif et
      await this.enableCameraAndMicrophone();

      // Meet'i yukle
      await this.load(meetId);

      // Meet'e katil
      await this.join();

      // Soket baglantisini kur
      await this.connectSocket();

      // Ses kaydini baslat
      this.startMediaRecorder();

      // Ses sentezleyiciyi ve konusma tanima tanimayi baslat
      await Promise.all([
        // this.startAvatarSynthesizer(),
        this.startSpeechSynthesizer(),
        this.startSpeechRecognizer(),
      ]);

      this.setAndEmitState("started");

      console.log("[Meet] Started", meetId);

      return this.data;
    } catch (error) {
      console.error("Meeting.start()", error);
      this.stop();
      this.error = error;
      this.setAndEmitState("error");
      throw error;
    }
  }

  // Meeti yukle
  async load(meetId) {
    console.log("[Meet] Loading", meetId);

    const { data } = await this.client(`/api/applicant/exam-meets/${meetId}`);

    this.id = data.id;
    this.type = data.type;
    this.data = {
      title: data.title,
      examId: data.exam_id,
      userId: data.user_id,
      startedAt: data.started_at ? new Date(data.started_at) : null,
      endDate: data.end_date ? new Date(data.end_date) : null,
      duration: data.duration,
      character: {
        lang: data?.case_meet?.lang,
        voice: data?.case_meet?.character?.voice?.key,
        backgroundType: data?.case_meet?.character?.background?.type,
        background:
          data?.case_meet?.character?.background?.video_url ||
          data?.case_meet?.character?.background?.image_url,
      },
    };

    this.setAvatarConfig({
      talkingAvatarCharacter:
        data?.case_meet?.character?.avatar_look?.avatar?.key,
      talkingAvatarStyle: data?.case_meet?.character?.avatar_look?.key,
    });

    this.emit("meetLoaded", this.data);
    console.log("[Meet] Loaded", meetId, this.data);
  }

  // Meete katil
  async join() {
    console.log("[Meet] Joining", this.id);

    const { data } = await this.client(
      `/api/applicant/exam-meets/${this.id}/join`,
      {
        method: "POST",
      }
    );

    const now = new Date();
    const endDate = data.end_date ? new Date(data.end_date) : null;
    const startedAt = data.started_at ? new Date(data.started_at) : null;
    const duration = data.duration;

    this.data.startedAt = startedAt;
    this.data.endDate = endDate;
    this.data.duration = duration;
    this.sessionId = Date.now();

    if (endDate) {
      const timeDiff = Math.max(endDate - now, 0);

      clearTimeout(this.timer);
      if (timeDiff > 0) {
        this.timer = setTimeout(() => {
          this.stop("timeout");
        }, timeDiff);
      }
    }

    this.emit("meetJoined");

    console.log("[Meet] Joined", this.id);
  }

  // Meeti bitir
  async end() {
    console.log("[Meet] Ending", this.id);

    await this.client(`/api/applicant/exam-meets/${this.id}/end`, {
      method: "POST",
    }).catch(console.error);

    await this.stop("user");

    this.emit("meetEnded", this.id);
    console.log("[Meet] Ended", this.id);
  }

  // Meeti durdur
  async stop(reason) {
    console.log("[Meet] Stopping", reason);

    try {
      // Kamera ve mikrafonu kapat
      const devices = Promise.all([
        this.localParticipant.setCameraEnabled(false),
        this.localParticipant.setMicrophoneEnabled(false),
      ]).catch(console.error);

      // Konusmaciyi ve ses tanimayi durdur
      const services = Promise.all([
        this.synthesizer?.stop(),
        this.recognizer?.stop(),
      ]).catch(console.error);

      // Ses kaydini durdur
      const recorder = this.mediaRecorder.stop().catch(console.error);

      // Soket baglantisini kapat
      this.echo.leave(`Meet.${this.data.userId}`);

      await Promise.all([devices, services, recorder]).catch(console.error);

      // Zamanlayiciyi durdur
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      console.log("[Meet] Stopped", reason);
      this.emit("meetStopped", { reason, id: this.id });
      this.setAndEmitState("idle");
    } catch (err) {
      console.error("[Meet] Error stopping", err);
      throw err;
    }
  }

  // ----- Question -----

  setQuestion(question) {
    this.question = question;
    this.emit("questionChanged", question);
  }

  // Soruyu yukle
  async loadQuestion() {
    try {
      const result = await this.client(`/api/applicant/case/qa/question`, {
        method: "POST",
        body: { exam_id: this.data.examId },
      });

      this.synthesizer.speak({
        text: result.data.question,
        lang: this.data.character.lang,
        voice: this.data.character.voice,
      });

      this.setQuestion(result.data);

      return result.data;
    } catch (error) {
      console.error("Meeting.loadQuestion()", error);
      throw error;
    }
  }

  // Cevap gonder
  async answerQuestion() {
    const text = this.recognizer.finalTranscript;

    if (text === "") {
      throw new Error("Cevap vermediniz");
    }

    if (!this.question) {
      throw new Error("Soru yüklenmedi");
    }

    const data = await this.client(
      `/api/applicant/exams/${this.data.examId}/meets/${this.id}/answer/qa`,
      {
        method: "POST",
        body: {
          question_id: this.question.id,
          text,
        },
      }
    );

    this.recognizer.finalTranscript = "";
    this.setQuestion(data.next_question);

    if (!data.next_question) {
      this.stop("finished");
    }

    return this.question;
  }

  // Soruyu konus
  async speakQuestion() {
    if (!this.question) {
      throw new Error("Soru yüklenmedi");
    }

    if (!this.question.question) {
      throw new Error("Soru yok");
    }

    if (this.synthesizer.isSpeaking) {
      await this.synthesizer.stopSpeaking();
    }

    await this.synthesizer.speak({
      text: this.question.question,
      lang: this.data.character.lang,
      voice: this.data.character.voice,
    });
  }
}
