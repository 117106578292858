import { Mutex } from "@livekit/mutex";
import { TrackEvent } from "../events";
import { isMobile } from "../utils";
import { Track, attachToElement, detachTrack } from "./Track";

export default class LocalTrack extends Track {
  constructor(mediaTrack, kind, constraints) {
    super(mediaTrack, kind);
    this.reacquireTrack = false;
    this.muteLock = new Mutex();
    this.restartLock = new Mutex();
    this.setMediaStreamTrack(mediaTrack, true);
    this.manuallyStopped = false;

    // added to satisfy TS compiler, constraints are synced with MediaStreamTrack
    this._constraints = mediaTrack.getConstraints();
    if (constraints) {
      this._constraints = constraints;
    }
  }

  get constraints() {
    return this._constraints;
  }

  get id() {
    return this._mediaStreamTrack.id;
  }

  get mediaStreamTrack() {
    return this._mediaStreamTrack;
  }

  get isLocal() {
    return true;
  }

  getSourceTrackSettings() {
    return this._mediaStreamTrack.getSettings();
  }

  async setMediaStreamTrack(newTrack, force) {
    if (newTrack === this._mediaStreamTrack && !force) {
      return;
    }
    if (this._mediaStreamTrack) {
      // detach
      this.attachedElements.forEach((el) => {
        detachTrack(this._mediaStreamTrack, el);
      });
      this._mediaStreamTrack.removeEventListener("ended", this.handleEnded);
    }

    this.mediaStream = new MediaStream([newTrack]);
    if (newTrack) {
      newTrack.addEventListener("ended", this.handleEnded);
      this._constraints = newTrack.getConstraints();
    }
    // if `newTrack` is different from the existing track, stop the
    // older track just before replacing it
    if (this._mediaStreamTrack !== newTrack) {
      this._mediaStreamTrack.stop();
    }
    this._mediaStreamTrack = newTrack;
    if (newTrack) {
      // sync muted state with the enabled state of the newly provided track
      this._mediaStreamTrack.enabled = !this.isMuted;
      this.attachedElements.forEach((el) => {
        attachToElement(newTrack, el);
      });
    }
  }

  async mute() {
    this.setTrackMuted(true);
    return this;
  }

  async unmute() {
    this.setTrackMuted(false);
    return this;
  }

  async restart(constraints) {
    this.manuallyStopped = false;
    const unlock = await this.restartLock.lock();
    try {
      if (!constraints) {
        constraints = this._constraints;
      }
      const { deviceId, ...otherConstraints } = this._constraints;
      console.debug("restarting track with constraints", { constraints });

      const streamConstraints = {
        audio: false,
        video: false,
      };

      if (this.kind === Track.Kind.Video) {
        streamConstraints.video = deviceId ? { deviceId } : true;
      } else {
        streamConstraints.audio = deviceId ? { deviceId } : true;
      }

      // these steps are duplicated from setMediaStreamTrack because we must stop
      // the previous tracks before new tracks can be acquired
      this.attachedElements.forEach((el) => {
        detachTrack(this.mediaStreamTrack, el);
      });
      this._mediaStreamTrack.removeEventListener("ended", this.handleEnded);
      // on Safari, the old audio track must be stopped before attempting to acquire
      // the new track, otherwise the new track will stop with
      // 'A MediaStreamTrack ended due to a capture failure`
      this._mediaStreamTrack.stop();

      // create new track and attach
      const mediaStream = await navigator.mediaDevices.getUserMedia(
        streamConstraints
      );
      const newTrack = mediaStream.getTracks()[0];
      await newTrack.applyConstraints(otherConstraints);
      newTrack.addEventListener("ended", this.handleEnded);
      console.debug("re-acquired MediaStreamTrack");

      await this.setMediaStreamTrack(newTrack);
      this._constraints = constraints;
      this.emit(TrackEvent.Restarted, this);
      if (this.manuallyStopped) {
        console.warn(
          "track was stopped during a restart, stopping restarted track"
        );
        this.stop();
      }
      return this;
    } finally {
      unlock();
    }
  }

  setTrackMuted(muted) {
    console.debug(`setting ${this.kind} track ${muted ? "muted" : "unmuted"}`);

    if (this.isMuted === muted && this._mediaStreamTrack.enabled !== muted) {
      return;
    }

    this.isMuted = muted;
    this._mediaStreamTrack.enabled = !muted;
    this.emit(muted ? TrackEvent.Muted : TrackEvent.Unmuted, this);
  }

  get needsReAcquisition() {
    return (
      this._mediaStreamTrack.readyState !== "live" ||
      this._mediaStreamTrack.muted ||
      !this._mediaStreamTrack.enabled ||
      this.reacquireTrack
    );
  }

  async handleAppVisibilityChanged() {
    await super.handleAppVisibilityChanged();
    if (!isMobile()) return;
    console.debug(
      `visibility changed, is in Background: ${this.isInBackground}`
    );

    if (!this.isInBackground && this.needsReAcquisition && !this.isMuted) {
      console.debug(`track needs to be reacquired, restarting ${this.source}`);
      await this.restart();
      this.reacquireTrack = false;
    }
  }

  handleEnded = () => {
    if (this.isInBackground) {
      this.reacquireTrack = true;
    }
    this.emit(TrackEvent.Ended, this);
  };

  stop() {
    this.manuallyStopped = true;
    super.stop();

    this._mediaStreamTrack.removeEventListener("ended", this.handleEnded);
  }
}
