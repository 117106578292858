import payload_plugin_qLmFnukI99 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_date_8GM8eUvAz4 from "/opt/buildhome/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-date.js";
import vuetify_icons_KAumv2pUni from "/opt/buildhome/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vfCt6Q18Tc from "/opt/buildhome/repo/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/opt/buildhome/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import emitter_client_ncpkhCHrSG from "/opt/buildhome/repo/plugins/emitter.client.js";
import global_client_gNm6n6rHun from "/opt/buildhome/repo/plugins/global.client.js";
import laravel_echo_client_RbVOyfQxz3 from "/opt/buildhome/repo/plugins/laravel-echo.client.js";
import meeting_client_b7J2rAtwin from "/opt/buildhome/repo/plugins/meeting.client.js";
import vue3_toastify_OGYNDsiW9E from "/opt/buildhome/repo/plugins/vue3-toastify.ts";
import vuetify_7h9QAQEssH from "/opt/buildhome/repo/plugins/vuetify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/buildhome/repo/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_date_8GM8eUvAz4,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_vfCt6Q18Tc,
  vuetify_no_client_hints_kyYn5AnnAK,
  emitter_client_ncpkhCHrSG,
  global_client_gNm6n6rHun,
  laravel_echo_client_RbVOyfQxz3,
  meeting_client_b7J2rAtwin,
  vue3_toastify_OGYNDsiW9E,
  vuetify_7h9QAQEssH,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]