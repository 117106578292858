import { EventEmitter } from "events";

export default defineNuxtPlugin((nuxtApp) => {
  const emitter = new EventEmitter();

  return {
    provide: {
      emitter: emitter,
    },
  };
});
