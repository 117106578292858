import { EventEmitter } from "events";
import { ParticipantEvent } from "../events";
import { Track } from "../track/Track";
import { isAudioTrack } from "../utils";

export default class Participant extends EventEmitter {
  constructor(sid) {
    super();

    this.setMaxListeners(100);
    this.sid = sid;
    this.audioTracks = new Map();
    this.videoTracks = new Map();
    this.tracks = new Map();
    this.isSpeaking = false;
    this.lastSpokeAt = null;
  }

  getTracks() {
    return Array.from(this.tracks.values());
  }

  getTrack(source) {
    for (const [, track] of this.tracks) {
      if (track.source === source) {
        return track;
      }
    }
  }

  get isCameraEnabled() {
    const track = this.getTrack(Track.Source.Camera);
    return !(track?.isMuted ?? true);
  }

  get isMicrophoneEnabled() {
    const track = this.getTrack(Track.Source.Microphone);
    return !(track?.isMuted ?? true);
  }

  get isScreenShareEnabled() {
    const track = this.getTrack(Track.Source.ScreenShare);
    return !!track;
  }

  get isLocal() {
    return false;
  }

  setIsSpeaking(speaking) {
    if (speaking === this.isSpeaking) {
      return;
    }
    this.isSpeaking = speaking;
    if (speaking) {
      this.lastSpokeAt = new Date();
    }
    this.emit(ParticipantEvent.IsSpeakingChanged, speaking);
  }

  setAudioContext(ctx) {
    this.audioContext = ctx;
    this.audioTrack.forEach(
      (track) => isAudioTrack(track.track) && track.track.setAudioContext(ctx)
    );
  }

  addTrack(track) {
    const sid = track.sid || track.mediaStreamID;

    this.tracks.set(sid, track);
    switch (track.kind) {
      case Track.Kind.Audio:
        this.audioTracks.set(sid, track);
        break;
      case Track.Kind.Video:
        this.videoTracks.set(sid, track);
        break;
      default:
        break;
    }

    this.emit(ParticipantEvent.TrackAdded, track);
  }

  removeTrack(sid) {
    const track = this.tracks.get(sid);
    if (!track) {
      return;
    }

    this.tracks.delete(sid);
    switch (track.kind) {
      case Track.Kind.Audio:
        this.audioTracks.delete(sid);
        break;
      case Track.Kind.Video:
        this.videoTracks.delete(sid);
        break;
      default:
        break;
    }

    this.emit(ParticipantEvent.TrackRemoved, track);
  }
}
