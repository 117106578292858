export default defineAppConfig({
  sanctum: {
    interceptors: {
      onRequest: async (app, ctx, logger) => {
        // ctx.options.headers.set(
        //   "X-Language",
        //   app.$i18n.localeProperties.value.code
        // );
      },
      onResponse: async (app, ctx, logger) => {
        if (process.client) {
          const snackbar = useSnackbar();

          if (ctx.response?.status === 500) {
            snackbar.error("Internal Server Error");
          } else if (ctx.response?.status == 422) {
            snackbar.error(ctx.response?._data?.message || "Validation Error");
          } else if (ctx.response?.status === 401) {
          } else if (ctx.response?.status === 400) {
            snackbar.error(ctx.response?._data?.message || "Bad Request");
          }
        }
      },
    },
  },
});
