import { sleep } from "../utils";
import { cloneDeep } from "lodash";

export function mergeDefaultOptions(options, audioDefaults, videoDefaults) {
  const clonedOptions = cloneDeep(options) ?? {};
  if (clonedOptions.audio === true) clonedOptions.audio = {};
  if (clonedOptions.video === true) clonedOptions.video = {};

  // use defaults
  if (clonedOptions.audio) {
    mergeObjectWithoutOverwriting(clonedOptions.audio, audioDefaults);
    clonedOptions.audio.deviceId ??= "default";
  }
  if (clonedOptions.video) {
    mergeObjectWithoutOverwriting(clonedOptions.video, videoDefaults);
    clonedOptions.video.deviceId ??= "default";
  }
  return clonedOptions;
}

function mergeObjectWithoutOverwriting(mainObject, objectToMerge) {
  Object.keys(objectToMerge).forEach((key) => {
    if (mainObject[key] === undefined) mainObject[key] = objectToMerge[key];
  });
  return mainObject;
}

export function constraintsForOptions(options) {
  const constraints = {};

  if (options.video) {
    // default video options
    if (typeof options.video === "object") {
      const videoOptions = {};
      const target = videoOptions;
      const source = options.video;
      Object.keys(source).forEach((key) => {
        switch (key) {
          case "resolution":
            // flatten VideoResolution fields
            mergeObjectWithoutOverwriting(target, source.resolution);
            break;
          default:
            target[key] = source[key];
        }
      });
      constraints.video = videoOptions;
      constraints.video.deviceId ??= "default";
    } else {
      constraints.video = options.video ? { deviceId: "default" } : false;
    }
  } else {
    constraints.video = false;
  }

  if (options.audio) {
    if (typeof options.audio === "object") {
      constraints.audio = options.audio;
      constraints.audio.deviceId ??= "default";
    } else {
      constraints.audio = { deviceId: "default" };
    }
  } else {
    constraints.audio = false;
  }
  return constraints;
}

export async function detectSilence(track, timeOffset = 200) {
  const ctx = getNewAudioContext();
  if (ctx) {
    const analyser = ctx.createAnalyser();
    analyser.fftSize = 2048;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const source = ctx.createMediaStreamSource(
      new MediaStream([track.mediaStreamTrack])
    );

    source.connect(analyser);
    await sleep(timeOffset);
    analyser.getByteTimeDomainData(dataArray);
    const someNoise = dataArray.some(
      (sample) => sample !== 128 && sample !== 0
    );
    ctx.close();
    return !someNoise;
  }
  return false;
}

export function getNewAudioContext() {
  const AudioContext =
    // @ts-ignore
    typeof window !== "undefined" &&
    (window.AudioContext || window.webkitAudioContext);
  if (AudioContext) {
    const audioContext = new AudioContext({ latencyHint: "interactive" });
    // If the audio context is suspended, we need to resume it when the user clicks on the page
    if (
      audioContext.state === "suspended" &&
      typeof window !== "undefined" &&
      window.document?.body
    ) {
      const handleResume = () => {
        audioContext.resume().then(() => {
          window.document.body?.removeEventListener("click", handleResume);
        });
      };
      window.document.body.addEventListener("click", handleResume);
    }
    return audioContext;
  }
}
