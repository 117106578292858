import { EventEmitter } from "events";
import { isFireFox, isSafari, isWeb } from "../utils";

const BACKGROUND_REACTION_DELAY = 5000;

const recycledElements = [];

export class Track extends EventEmitter {
  sid;
  mediaStream;
  rtpTimestamp;
  backgroundTimeout;
  timeSyncHandle;

  constructor(mediaTrack, kind) {
    super();
    this.setMaxListeners(100);
    this.kind = kind;
    this._mediaStreamTrack = mediaTrack;
    this._mediaStreamID = mediaTrack.id;
    this.source = Track.Source.Unknown;

    this.attachedElements = [];
    this.isMuted = false;
    this.isInBackground = false;
    this._currentBitrate = 0;
  }

  get currentBitrate() {
    return this._currentBitrate;
  }

  get mediaStreamTrack() {
    return this._mediaStreamTrack;
  }

  get mediaStreamID() {
    return this._mediaStreamID;
  }

  attach(element) {
    let elementType = "audio";
    if (this.kind === Track.Kind.Video) {
      elementType = "video";
    }
    if (this.attachedElements.length === 0 && this.kind === Track.Kind.Video) {
      this.addAppVisibilityListener();
    }
    if (!element) {
      if (elementType === "audio") {
        recycledElements.forEach((e) => {
          if (e.parentElement === null && !element) {
            element = e;
          }
        });
        if (element) {
          // remove it from pool
          recycledElements.splice(recycledElements.indexOf(element), 1);
        }
      }
      if (!element) {
        element = document.createElement(elementType);
      }
    }

    if (!this.attachedElements.includes(element)) {
      this.attachedElements.push(element);
    }

    // even if we believe it's already attached to the element, it's possible
    // the element's srcObject was set to something else out of band.
    // we'll want to re-attach it in that case
    attachToElement(this.mediaStreamTrack, element);

    // handle auto playback failures
    const allMediaStreamTracks = element.srcObject.getTracks();
    const hasAudio = allMediaStreamTracks.some((tr) => tr.kind === "audio");

    // manually play media to detect auto playback status
    element
      .play()
      .then(() => {
        this.emit(
          hasAudio
            ? TrackEvent.AudioPlaybackStarted
            : TrackEvent.VideoPlaybackStarted
        );
      })
      .catch((e) => {
        if (e.name === "NotAllowedError") {
          this.emit(
            hasAudio
              ? TrackEvent.AudioPlaybackFailed
              : TrackEvent.VideoPlaybackFailed,
            e
          );
        } else if (e.name === "AbortError") {
          // commonly triggered by another `play` request, only log for debugging purposes
          console.debug(
            `${
              hasAudio ? "audio" : "video"
            } playback aborted, likely due to new play request`
          );
        } else {
          console.warn(`could not playback ${hasAudio ? "audio" : "video"}`, e);
        }
        // If audio playback isn't allowed make sure we still play back the video
        if (
          hasAudio &&
          element &&
          allMediaStreamTracks.some((tr) => tr.kind === "video") &&
          e.name === "NotAllowedError"
        ) {
          element.muted = true;
          element.play().catch(() => {
            // catch for Safari, exceeded options at this point to automatically play the media element
          });
        }
      });

    this.emit(TrackEvent.ElementAttached, element);
    return element;
  }

  detach(element) {
    try {
      // detach from a single element
      if (element) {
        detachTrack(this.mediaStreamTrack, element);
        const idx = this.attachedElements.indexOf(element);
        if (idx >= 0) {
          this.attachedElements.splice(idx, 1);
          this.recycleElement(element);
          this.emit(TrackEvent.ElementDetached, element);
        }
        return element;
      }

      const detached = [];
      this.attachedElements.forEach((elm) => {
        detachTrack(this.mediaStreamTrack, elm);
        detached.push(elm);
        this.recycleElement(elm);
        this.emit(TrackEvent.ElementDetached, elm);
      });

      // remove all tracks
      this.attachedElements = [];
      return detached;
    } finally {
      if (this.attachedElements.length === 0) {
        this.removeAppVisibilityListener();
      }
    }
  }

  stop() {
    this._mediaStreamTrack.stop();
  }

  enable() {
    this._mediaStreamTrack.enabled = true;
  }

  disable() {
    this._mediaStreamTrack.enabled = false;
  }

  recycleElement(element) {
    if (element instanceof HTMLAudioElement) {
      // we only need to re-use a single element
      let shouldCache = true;
      element.pause();
      recycledElements.forEach((e) => {
        if (!e.parentElement) {
          shouldCache = false;
        }
      });
      if (shouldCache) {
        recycledElements.push(element);
      }
    }
  }

  appVisibilityChangedListener = () => {
    if (this.backgroundTimeout) {
      clearTimeout(this.backgroundTimeout);
    }
    // delay app visibility update if it goes to hidden
    // update immediately if it comes back to focus
    if (document.visibilityState === "hidden") {
      this.backgroundTimeout = setTimeout(
        () => this.handleAppVisibilityChanged(),
        BACKGROUND_REACTION_DELAY
      );
    } else {
      this.handleAppVisibilityChanged();
    }
  };

  async handleAppVisibilityChanged() {
    this.isInBackground = document.visibilityState === "hidden";
    if (!this.isInBackground && this.kind === Track.Kind.Video) {
      setTimeout(
        () =>
          this.attachedElements.forEach((el) =>
            el.play().catch(() => {
              /** catch clause necessary for Safari */
            })
          ),
        0
      );
    }
  }

  addAppVisibilityListener() {
    if (isWeb()) {
      this.isInBackground = document.visibilityState === "hidden";
      document.addEventListener(
        "visibilitychange",
        this.appVisibilityChangedListener
      );
    } else {
      this.isInBackground = false;
    }
  }

  removeAppVisibilityListener() {
    if (isWeb()) {
      document.removeEventListener(
        "visibilitychange",
        this.appVisibilityChangedListener
      );
    }
  }
}

export function attachToElement(track, element) {
  let mediaStream;
  if (element.srcObject instanceof MediaStream) {
    mediaStream = element.srcObject;
  } else {
    mediaStream = new MediaStream();
  }

  // check if track matches existing track
  let existingTracks;
  if (track.kind === "audio") {
    existingTracks = mediaStream.getAudioTracks();
  } else {
    existingTracks = mediaStream.getVideoTracks();
  }
  if (!existingTracks.includes(track)) {
    existingTracks.forEach((et) => {
      mediaStream.removeTrack(et);
    });
    mediaStream.addTrack(track);
  }

  if (!isSafari() || !(element instanceof HTMLVideoElement)) {
    // when in low power mode (applies to both macOS and iOS), Safari will show a play/pause overlay
    // when a video starts that has the `autoplay` attribute is set.
    // we work around this by _not_ setting the autoplay attribute on safari and instead call `setTimeout(() => el.play(),0)` further down
    element.autoplay = true;
  }
  // In case there are no audio tracks present on the mediastream, we set the element as muted to ensure autoplay works
  element.muted = mediaStream.getAudioTracks().length === 0;
  if (element instanceof HTMLVideoElement) {
    element.playsInline = true;
  }

  // avoid flicker
  if (element.srcObject !== mediaStream) {
    element.srcObject = mediaStream;
    if ((isSafari() || isFireFox()) && element instanceof HTMLVideoElement) {
      // Firefox also has a timing issue where video doesn't actually get attached unless
      // performed out-of-band
      // Safari 15 has a bug where in certain layouts, video element renders
      // black until the page is resized or other changes take place.
      // Resetting the src triggers it to render.
      // https://developer.apple.com/forums/thread/690523
      setTimeout(() => {
        element.srcObject = mediaStream;
        // Safari 15 sometimes fails to start a video
        // when the window is backgrounded before the first frame is drawn
        // manually calling play here seems to fix that
        element.play().catch(() => {
          /** do nothing */
        });
      }, 0);
    }
  }
}

export function detachTrack(track, element) {
  if (element.srcObject instanceof MediaStream) {
    const mediaStream = element.srcObject;
    mediaStream.removeTrack(track);
    if (mediaStream.getTracks().length > 0) {
      element.srcObject = mediaStream;
    } else {
      element.srcObject = null;
    }
  }
}

Track.Kind = Object.freeze({
  Audio: "audio",
  Video: "video",
  Unknown: "unknown",
});

Track.Source = Object.freeze({
  Camera: "camera",
  Microphone: "microphone",
  ScreenShare: "screen_share",
  ScreenShareAudio: "screen_share_audio",
  Unknown: "unknown",
});
