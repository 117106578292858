import { default as kyc0vwkNkEyGdMeta } from "/opt/buildhome/repo/pages/account/kyc.vue?macro=true";
import { default as examssUQrPqqnYBMeta } from "/opt/buildhome/repo/pages/assessments/[hash]/exams.vue?macro=true";
import { default as joinCl204Rn6znMeta } from "/opt/buildhome/repo/pages/assessments/[hash]/join.vue?macro=true";
import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as _91token_93Oy9e00nMWcMeta } from "/opt/buildhome/repo/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91number_93_45pageLgA1ozFoagMeta } from "/opt/buildhome/repo/pages/exam/default/[number]-page.vue?macro=true";
import { default as company_45info2rCpIv7FlRMeta } from "/opt/buildhome/repo/pages/exam/default/company-info.vue?macro=true";
import { default as inboxe2oTueFj9eMeta } from "/opt/buildhome/repo/pages/exam/default/inbox.vue?macro=true";
import { default as meetingEoBQBl4OLNMeta } from "/opt/buildhome/repo/pages/exam/default/meeting.vue?macro=true";
import { default as meetingsg96zSzmjBgMeta } from "/opt/buildhome/repo/pages/exam/default/meetings.vue?macro=true";
import { default as plansxgXwdkRjTIMeta } from "/opt/buildhome/repo/pages/exam/default/plans.vue?macro=true";
import { default as workers81H1Sv3saNMeta } from "/opt/buildhome/repo/pages/exam/default/workers.vue?macro=true";
import { default as worksYErYHmTyBYMeta } from "/opt/buildhome/repo/pages/exam/default/works.vue?macro=true";
import { default as defaultX6jzn4lFz8Meta } from "/opt/buildhome/repo/pages/exam/default.vue?macro=true";
import { default as only_45meetNAerFEjzoLMeta } from "/opt/buildhome/repo/pages/exam/only-meet.vue?macro=true";
import { default as examMjqMe6mCyVMeta } from "/opt/buildhome/repo/pages/exam.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "account-kyc",
    path: "/account/kyc",
    meta: kyc0vwkNkEyGdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/kyc.vue")
  },
  {
    name: "assessments-hash-exams",
    path: "/assessments/:hash()/exams",
    meta: examssUQrPqqnYBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/assessments/[hash]/exams.vue")
  },
  {
    name: "assessments-hash-join",
    path: "/assessments/:hash()/join",
    meta: joinCl204Rn6znMeta || {},
    component: () => import("/opt/buildhome/repo/pages/assessments/[hash]/join.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93Oy9e00nMWcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password/[token].vue")
  },
  {
    name: "exam",
    path: "/exam",
    meta: examMjqMe6mCyVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/exam.vue"),
    children: [
  {
    name: "exam-default",
    path: "default",
    component: () => import("/opt/buildhome/repo/pages/exam/default.vue"),
    children: [
  {
    name: "exam-default-number-page",
    path: ":number()-page",
    component: () => import("/opt/buildhome/repo/pages/exam/default/[number]-page.vue")
  },
  {
    name: "exam-default-company-info",
    path: "company-info",
    component: () => import("/opt/buildhome/repo/pages/exam/default/company-info.vue")
  },
  {
    name: "exam-default-inbox",
    path: "inbox",
    component: () => import("/opt/buildhome/repo/pages/exam/default/inbox.vue")
  },
  {
    name: "exam-default-meeting",
    path: "meeting",
    component: () => import("/opt/buildhome/repo/pages/exam/default/meeting.vue")
  },
  {
    name: "exam-default-meetings",
    path: "meetings",
    component: () => import("/opt/buildhome/repo/pages/exam/default/meetings.vue")
  },
  {
    name: "exam-default-plans",
    path: "plans",
    component: () => import("/opt/buildhome/repo/pages/exam/default/plans.vue")
  },
  {
    name: "exam-default-workers",
    path: "workers",
    component: () => import("/opt/buildhome/repo/pages/exam/default/workers.vue")
  },
  {
    name: "exam-default-works",
    path: "works",
    component: () => import("/opt/buildhome/repo/pages/exam/default/works.vue")
  }
]
  },
  {
    name: "exam-only-meet",
    path: "only-meet",
    component: () => import("/opt/buildhome/repo/pages/exam/only-meet.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  }
]