import SpeechRecognizer from "./SpeechRecognizer";
import AvatarSynthesizer from "./AvatarSynthesizer";
import SpeechSynthesizer from "./SpeechSynthesizer";

export default class CognitiveService {
  static getSpeechRecognizer() {
    if (!this.speechRecognizer) {
      this.speechRecognizer = new SpeechRecognizer();
    }
    return this.speechRecognizer;
  }

  static getAvatarSynthesizer() {
    if (!this.avatarSynthesizer) {
      this.avatarSynthesizer = new AvatarSynthesizer();
    }
    return this.avatarSynthesizer;
  }

  static getSpeechSynthesizer() {
    if (!this.speechSynthesizer) {
      this.speechSynthesizer = new SpeechSynthesizer();
    }
    return this.speechSynthesizer;
  }
}
