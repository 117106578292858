import { EventEmitter } from "events";

export default class CustomMediaRecorder extends EventEmitter {
  constructor(options, mediaStream) {
    super();
    this.setOptions(options);
    this.mediaStream = mediaStream;
    this.recorder = null;
    this.mediaChunks = [];
    this.error = null;
    this.onData = () => {};
    this.onStartRecording = () => {};
    this.onStopRecording = () => {};
    this.onRequestDataRecording = () => {};
  }

  setOptions(options) {
    this.options = Object.assign(
      {
        debug: false,
      },
      options
    );
  }

  getMediaStream() {
    if (this.mediaStream) {
      return Promise.resolve(this.mediaStream);
    }

    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaStream = stream;
          resolve(stream);
        })
        .catch(reject);
    });
  }

  get state() {
    return this.recorder?.state ?? "inactive";
  }

  get isRecording() {
    return this.state === "recording";
  }

  emitCurrentState() {
    this.emit("state", this.state);
  }

  onDataAvailable(event) {
    if (this.options.debug) {
      console.log("[AudioRecorder] Data available", event.data);
    }

    if (event.data && event.data.size > 0) {
      this.mediaChunks.push(event.data);

      this.onData(event.data, this.mediaChunks);
      this.emit("data", event.data, this.mediaChunks);
    }

    this.onRequestDataRecording(
      new Blob(this.mediaChunks, { type: this.options.mimeType })
    );
  }

  onStart() {
    if (this.options.debug) {
      console.log("[AudioRecorder] Recording started");
    }

    this.emitCurrentState();
    this.onStartRecording();
  }

  onPause() {
    if (this.options.debug) {
      console.log("[AudioRecorder] Recording paused");
    }

    this.emitCurrentState();
  }

  onResume() {
    if (this.options.debug) {
      console.log("[AudioRecorder] Recording resumed");
    }

    this.emitCurrentState();
  }

  onStop() {
    if (this.options.debug) {
      console.log("[AudioRecorder] Recording stopped");
    }

    this.emitCurrentState();
    this.onStopRecording(
      new Blob(this.mediaChunks, { type: this.options.mimeType })
    );
  }

  onError(error) {
    if (this.options.debug) {
      console.error("[AudioRecorder] Error", error);
    }

    this.error = error;
    this.emit("error", error);
  }

  async start(timeslice) {
    if (this.recorder && this.state !== "inactive") {
      await this.stop();
    }

    this.error = null;
    this.mediaChunks = [];

    const mediaStream = await this.getMediaStream();

    this.recorder = new MediaRecorder(mediaStream, this.options);
    this.recorder.ondataavailable = this.onDataAvailable.bind(this);
    this.recorder.onstart = this.onStart.bind(this);
    this.recorder.onstop = this.onStop.bind(this);
    this.recorder.onpause = this.onPause.bind(this);
    this.recorder.onresume = this.onResume.bind(this);
    this.recorder.onerror = this.onError.bind(this);
    this.recorder.start(timeslice);

    await new Promise((resolve) => {
      this.onStartRecording = resolve;
    });
  }

  async stop() {
    if (this.recorder && this.state !== "inactive") {
      this.recorder.stop();

      return new Promise((resolve) => {
        this.onStopRecording = resolve;
      }).then((data) => {
        this.recorder = null;
        this.onData = () => {};
        return data;
      });
    }
  }

  pause() {
    if (this.recorder && this.state === "recording") {
      this.recorder.pause();
    }
  }

  resume() {
    if (this.recorder && this.state === "paused") {
      this.recorder.resume();
    }
  }

  requestData() {
    if (!this.recorder) {
      throw new Error("Recorder is not initialized");
    }

    if (this.state === "inactive") {
      throw new Error("Recorder is not recording");
    }

    return new Promise((resolve) => {
      this.onRequestDataRecording = resolve;
      this.recorder.requestData();
    });
  }
}
