import { isMobile } from "../utils";
import LocalTrack from "./LocalTrack";
import { Track } from "./Track";
import { constraintsForOptions } from "./utils";

export default class LocalVideoTrack extends LocalTrack {
  constructor(mediaTrack, constraints) {
    super(mediaTrack, Track.Kind.Video, constraints);
  }

  stop() {
    this._mediaStreamTrack.getConstraints();
    super.stop();
  }

  async mute() {
    const unlock = await this.muteLock.lock();
    try {
      if (this.isMuted) {
        console.debug("Track already muted");
        return this;
      }

      if (this.source === Track.Source.Camera) {
        console.debug("stopping camera track");
        // also stop the track, so that camera indicator is turned off
        this._mediaStreamTrack.stop();
      }
      await super.mute();
      return this;
    } finally {
      unlock();
    }
  }

  async unmute() {
    const unlock = await this.muteLock.lock();
    try {
      if (!this.isMuted) {
        console.debug("Track already unmuted");
        return this;
      }

      if (this.source === Track.Source.Camera) {
        console.debug("reacquiring camera track");
        await this.restartTrack();
      }
      await super.unmute();
      return this;
    } finally {
      unlock();
    }
  }

  setTrackMuted(muted) {
    super.setTrackMuted(muted);
  }

  async restartTrack(options) {
    let constraints;
    if (options) {
      const streamConstraints = constraintsForOptions({ video: options });
      if (typeof streamConstraints.video !== "boolean") {
        constraints = streamConstraints.video;
      }
    }
    await this.restart(constraints);
  }

  async handleAppVisibilityChanged() {
    await super.handleAppVisibilityChanged();
    if (!isMobile()) return;
    if (this.isInBackground && this.source === Track.Source.Camera) {
      this._mediaStreamTrack.enabled = false;
    }
  }
}
