import { DeviceUnsupportedError, TrackInvalidError } from "../errors";
import { ParticipantEvent } from "../events";
import LocalAudioTrack from "../track/LocalAudioTrack";
import LocalVideoTrack from "../track/LocalVideoTrack";
import { Track } from "../track/Track";
import { ScreenSharePresets } from "../track/options";
import { isSafari17, mediaTrackToLocalTrack } from "../utils";
import Participant from "./Participant";

export default class LocalParticipant extends Participant {
  constructor(sid) {
    super(sid);

    this.audioTrack = new Map();
    this.videoTrack = new Map();
    this.track = new Map();
    this.cameraError = null;
    this.microphoneError = null;
  }

  get isLocal() {
    return true;
  }

  get lastCameraError() {
    return this.cameraError;
  }

  get lastMicrophoneError() {
    return this.microphoneError;
  }

  setCameraEnabled(enabled, options) {
    return this.setTrackEnabled(Track.Source.Camera, enabled, options);
  }

  setMicrophoneEnabled(enabled, options) {
    return this.setTrackEnabled(Track.Source.Microphone, enabled, options);
  }

  setScreenShareEnabled(enabled, options) {
    return this.setTrackEnabled(Track.Source.ScreenShare, enabled, options);
  }

  async enableCameraAndMicrophone() {
    const tracks = await this.createTracks({
      audio: true,
      video: true,
    });

    await Promise.all(tracks.map((track) => this.addTrack(track)));

    return tracks;
  }

  async createTracks(constraints) {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      if (err instanceof Error) {
        if (constraints.audio) {
          this.microphoneError = err;
        }
        if (constraints.video) {
          this.cameraError = err;
        }
      }

      throw err;
    }

    if (constraints.audio) {
      this.microphoneError = undefined;
      this.emit(ParticipantEvent.AudioStreamAcquired);
    }
    if (constraints.video) {
      this.cameraError = undefined;
    }

    return Promise.all(
      stream.getTracks().map(async (mediaStreamTrack) => {
        const isAudio = mediaStreamTrack.kind === "audio";
        let trackConstraints;
        const conOrBool = isAudio ? constraints.audio : constraints.video;
        if (typeof conOrBool !== "boolean") {
          trackConstraints = conOrBool;
        }
        const track = mediaTrackToLocalTrack(
          mediaStreamTrack,
          trackConstraints
        );
        if (track.kind === Track.Kind.Video) {
          track.source = Track.Source.Camera;
        } else if (track.kind === Track.Kind.Audio) {
          track.source = Track.Source.Microphone;
          track.setAudioContext(this.audioContext);
        }
        track.mediaStream = stream;
        return track;
      })
    );
  }

  async createScreenTracks(options) {
    if (options === undefined) {
      options = {};
    }

    if (navigator.mediaDevices.getDisplayMedia === undefined) {
      throw new DeviceUnsupportedError("getDisplayMedia not supported");
    }

    if (options.resolution === undefined && !isSafari17()) {
      // we need to constrain the dimensions, otherwise it could lead to low bitrate
      // due to encoding a huge video. Encoding such large surfaces is really expensive
      // unfortunately Safari 17 has a but and cannot be constrained by default
      options.resolution = ScreenSharePresets.h1080fps30.resolution;
    }

    const constraints = screenCaptureToDisplayMediaStreamOptions(options);
    const stream = await navigator.mediaDevices.getDisplayMedia(constraints);

    const tracks = stream.getVideoTracks();
    if (tracks.length === 0) {
      throw new TrackInvalidError("no video track found");
    }
    const screenVideo = new LocalVideoTrack(tracks[0], undefined);
    screenVideo.source = Track.Source.ScreenShare;
    if (options.contentHint) {
      screenVideo.mediaStreamTrack.contentHint = options.contentHint;
    }

    const localTracks = [screenVideo];
    if (stream.getAudioTracks().length > 0) {
      this.emit(ParticipantEvent.AudioStreamAcquired);
      const screenAudio = new LocalAudioTrack(
        stream.getAudioTracks()[0],
        undefined,
        this.audioContext
      );
      screenAudio.source = Track.Source.ScreenShareAudio;
      localTracks.push(screenAudio);
    }
    return localTracks;
  }

  async setTrackEnabled(source, enabled = true, options) {
    let track = this.getTrack(source);

    if (enabled) {
      if (track) {
        await track.unmute(); // ?
      } else {
        let localTracks = null;
        try {
          switch (source) {
            case Track.Source.Camera:
              localTracks = await this.createTracks({
                video: options ?? true,
              });
              break;
            case Track.Source.Microphone:
              localTracks = await this.createTracks({
                audio: options ?? true,
              });
              break;
            case Track.Source.ScreenShare:
              localTracks = await this.createScreenTracks({ ...options });
              break;
            default:
              throw new TrackInvalidError(source);
          }
          for (const localTrack of localTracks) {
            this.addTrack(localTrack);
          }
          [track] = localTracks;
        } catch (e) {
          localTracks?.forEach((tr) => {
            tr.stop();
          });
          if (e instanceof Error) {
            this.emit(ParticipantEvent.MediaDevicesError, e);
          }
          throw e;
        }
      }
    } else {
      if (track) {
        if (source === Track.Source.ScreenShare) {
          track = await this.removeTrack(track);
          const screenAudioTrack = this.getTrack(Track.Source.ScreenShareAudio);
          if (screenAudioTrack) {
            this.removeTrack(screenAudioTrack);
          }
        } else {
          await track.mute();
        }
      }
    }

    return track;
  }
}
