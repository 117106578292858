export const ParticipantEvent = Object.freeze({
  TrackMuted: "trackMuted",
  TrackUnmuted: "trackUnmuted",
  DataReceived: "dataReceived",
  IsSpeakingChanged: "isSpeakingChanged",
  TrackStreamStateChanged: "trackStreamStateChanged",
  MediaDevicesError: "mediaDevicesError",
  AudioStreamAcquired: "audioStreamAcquired",
  PCTrackAdded: "pcTrackAdded",
  TrackAdded: "trackAdded",
  TrackRemoved: "trackRemoved",
});

export const TrackEvent = Object.freeze({
  Message: "message",
  Muted: "muted",
  Unmuted: "unmuted",
  Restarted: "restarted",
  Ended: "ended",
  Subscribed: "subscribed",
  Unsubscribed: "unsubscribed",
  UpdateSettings: "updateSettings",
  UpdateSubscription: "updateSubscription",
  AudioPlaybackStarted: "audioPlaybackStarted",
  AudioPlaybackFailed: "audioPlaybackFailed",
  AudioSilenceDetected: "audioSilenceDetected",
  VisibilityChanged: "visibilityChanged",
  VideoDimensionsChanged: "videoDimensionsChanged",
  VideoPlaybackStarted: "videoPlaybackStarted",
  VideoPlaybackFailed: "videoPlaybackFailed",
  ElementAttached: "elementAttached",
  ElementDetached: "elementDetached",
  UpstreamPaused: "upstreamPaused",
  UpstreamResumed: "upstreamResumed",
  SubscriptionPermissionChanged: "subscriptionPermissionChanged",
  SubscriptionStatusChanged: "subscriptionStatusChanged",
  SubscriptionFailed: "subscriptionFailed",
  TrackProcessorUpdate: "trackProcessorUpdate",
  AudioTrackFeatureUpdate: "audioTrackFeatureUpdate",
  TranscriptionReceived: "transcriptionReceived",
  TimeSyncUpdate: "timeSyncUpdate",
});
