import Meeting from "~/utils/meet/Meeting";

export default defineNuxtPlugin({
  name: "meeting.client",
  dependsOn: ["laravel-echo"],
  setup: (nuxtApp) => {
    const meeting = new Meeting(nuxtApp);
    window.meeting = meeting;
    return {
      provide: {
        meeting,
      },
    };
  },
});
