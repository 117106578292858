import { Track } from "./track/Track";
import LocalAudioTrack from "./track/LocalAudioTrack";
import LocalVideoTrack from "./track/LocalVideoTrack";
import { TrackInvalidError } from "./errors";
import { getNewAudioContext } from "./track/utils";

const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;
let browserDetails;

export function getBrowser(userAgent, force = true) {
  if (typeof userAgent === "undefined" && typeof navigator === "undefined") {
    return;
  }
  const ua = (userAgent ?? navigator.userAgent).toLowerCase();
  if (browserDetails === undefined || force) {
    const browser = browsersList.find(({ test }) => test.test(ua));
    browserDetails = browser?.describe(ua);
  }
  return browserDetails;
}

const browsersList = [
  {
    test: /firefox|iceweasel|fxios/i,
    describe(ua) {
      const browser = {
        name: "Firefox",
        version: getMatch(
          /(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i,
          ua
        ),
        os: ua.toLowerCase().includes("fxios") ? "iOS" : undefined,
        osVersion: getOSVersion(ua),
      };
      return browser;
    },
  },
  {
    test: /chrom|crios|crmo/i,
    describe(ua) {
      const browser = {
        name: "Chrome",
        version: getMatch(
          /(?:chrome|chromium|crios|crmo)\/(\d+(\.?_?\d+)+)/i,
          ua
        ),
        os: ua.toLowerCase().includes("crios") ? "iOS" : undefined,
        osVersion: getOSVersion(ua),
      };

      return browser;
    },
  },
  /* Safari */
  {
    test: /safari|applewebkit/i,
    describe(ua) {
      const browser = {
        name: "Safari",
        version: getMatch(commonVersionIdentifier, ua),
        os: ua.includes("mobile/") ? "iOS" : "macOS",
        osVersion: getOSVersion(ua),
      };

      return browser;
    },
  },
];

function getOSVersion(ua) {
  return ua.includes("mac os")
    ? getMatch(/\(.+?(\d+_\d+(:?_\d+)?)/, ua, 1).replace(/_/g, ".")
    : undefined;
}

function getMatch(exp, ua, id = 1) {
  const match = ua.match(exp);
  return (match && match.length >= id && match[id]) || "";
}

// ----------------------------

export function supportsSetSinkId(elm) {
  if (!document) {
    return false;
  }
  if (!elm) {
    elm = document.createElement("audio");
  }
  return "setSinkId" in elm;
}

export function isFireFox() {
  return getBrowser()?.name === "Firefox";
}

export function isSafari() {
  return getBrowser()?.name === "Safari";
}

export function isWeb() {
  return typeof document !== "undefined";
}

export function isReactNative() {
  // navigator.product is deprecated on browsers, but will be set appropriately for react-native.
  return navigator.product == "ReactNative";
}

export function isSafari17() {
  const b = getBrowser();
  return b?.name === "Safari" && b.version.startsWith("17.");
}

export function isMobile() {
  if (!isWeb()) return false;

  return (
    // @ts-expect-error `userAgentData` is not yet part of typescript
    navigator.userAgentData?.mobile ??
    /Tablet|iPad|Mobile|Android|BlackBerry/.test(navigator.userAgent)
  );
}

export function screenCaptureToDisplayMediaStreamOptions(options) {
  let videoConstraints = options.video ?? true;
  // treat 0 as uncapped
  if (
    options.resolution &&
    options.resolution.width > 0 &&
    options.resolution.height > 0
  ) {
    videoConstraints =
      typeof videoConstraints === "boolean" ? {} : videoConstraints;
    if (isSafari()) {
      videoConstraints = {
        ...videoConstraints,
        width: { max: options.resolution.width },
        height: { max: options.resolution.height },
        frameRate: options.resolution.frameRate,
      };
    } else {
      videoConstraints = {
        ...videoConstraints,
        width: { ideal: options.resolution.width },
        height: { ideal: options.resolution.height },
        frameRate: options.resolution.frameRate,
      };
    }
  }
}

function mergeObjectWithoutOverwriting(mainObject, objectToMerge) {
  Object.keys(objectToMerge).forEach((key) => {
    if (mainObject[key] === undefined) mainObject[key] = objectToMerge[key];
  });
  return mainObject;
}

export function constraintsForOptions(options) {
  const constraints = {};

  if (options.video) {
    // default video options
    if (typeof options.video === "object") {
      const videoOptions = {};
      const target = videoOptions;
      const source = options.video;
      Object.keys(source).forEach((key) => {
        switch (key) {
          case "resolution":
            // flatten VideoResolution fields
            mergeObjectWithoutOverwriting(target, source.resolution);
            break;
          default:
            target[key] = source[key];
        }
      });
      constraints.video = videoOptions;
    } else {
      constraints.video = options.video;
    }
  } else {
    constraints.video = false;
  }

  if (options.audio) {
    if (typeof options.audio === "object") {
      constraints.audio = options.audio;
    } else {
      constraints.audio = true;
    }
  } else {
    constraints.audio = false;
  }
  return constraints;
}

export function mediaTrackToLocalTrack(mediaStreamTrack, constraints) {
  switch (mediaStreamTrack.kind) {
    case Track.Kind.Audio:
      return new LocalAudioTrack(mediaStreamTrack, constraints);
    case Track.Kind.Video:
      return new LocalVideoTrack(mediaStreamTrack, constraints);
    default:
      throw new TrackInvalidError(
        `unsupported track type: ${mediaStreamTrack.kind}`
      );
  }
}

export function unwrapConstraint(constraint) {
  if (typeof constraint === "string" || typeof constraint === "number") {
    return constraint;
  }

  if (Array.isArray(constraint)) {
    return constraint[0];
  }
  if (constraint.exact) {
    if (Array.isArray(constraint.exact)) {
      return constraint.exact[0];
    }
    return constraint.exact;
  }
  if (constraint.ideal) {
    if (Array.isArray(constraint.ideal)) {
      return constraint.ideal[0];
    }
    return constraint.ideal;
  }
  throw Error("could not unwrap constraint");
}

export async function sleep(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export function isAudioTrack(track) {
  return !!track && track.kind == Track.Kind.Audio;
}

const separator = "|";

export function unpackStreamId(packed) {
  const parts = packed.split(separator);
  if (parts.length > 1) {
    return [parts[0], packed.substr(parts[0].length + 1)];
  }
  return [packed, ""];
}

export function htmlEncode(text) {
  const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
  };

  return String(text).replace(/[&<>"'\/]/g, (match) => entityMap[match]);
}

export function concatTranscripts(...transcriptParts) {
  return transcriptParts
    .map((t) => t.trim())
    .join(" ")
    .trim();
}

export function calculateRemainingTime(startDate, durationMinutes) {
  if (!startDate || !durationMinutes) {
    throw new Error("Hem başlangıç tarihi hem de süre gereklidir.");
  }

  const now = new Date();
  const start = new Date(startDate);

  if (isNaN(start.getTime()) || typeof durationMinutes !== "number") {
    throw new Error("Geçerli bir başlangıç tarihi ve sayı türünde süre girin.");
  }

  const durationMs = durationMinutes * 60 * 1000;
  const endDate = new Date(start.getTime() + durationMs);

  const remainingMs = endDate - now;

  return remainingMs > 0 ? remainingMs : 0;
}

/**
 * Creates and returns an analyser web audio node that is attached to the provided track.
 * Additionally returns a convenience method `calculateVolume` to perform instant volume readings on that track.
 * Call the returned `cleanup` function to close the audioContext that has been created for the instance of this helper
 */
export function createAudioAnalyser(track, options = {}) {
  const opts = {
    cloneTrack: false,
    fftSize: 2048,
    smoothingTimeConstant: 0.8,
    minDecibels: -100,
    maxDecibels: -80,
    ...options,
  };
  const audioContext = getNewAudioContext();

  if (!audioContext) {
    throw new Error("Audio Context not supported on this browser");
  }

  const streamTrack = opts.cloneTrack
    ? track.mediaStreamTrack.clone()
    : track.mediaStreamTrack;
  const mediaStreamSource = audioContext.createMediaStreamSource(
    new MediaStream([streamTrack])
  );
  const analyser = audioContext.createAnalyser();
  analyser.minDecibels = opts.minDecibels;
  analyser.maxDecibels = opts.maxDecibels;
  analyser.fftSize = opts.fftSize;
  analyser.smoothingTimeConstant = opts.smoothingTimeConstant;

  mediaStreamSource.connect(analyser);
  const dataArray = new Uint8Array(analyser.frequencyBinCount);

  /**
   * Calculates the current volume of the track in the range from 0 to 1
   */
  const calculateVolume = () => {
    analyser.getByteFrequencyData(dataArray);
    let sum = 0;
    for (const amplitude of dataArray) {
      sum += Math.pow(amplitude / 255, 2);
    }
    const volume = Math.sqrt(sum / dataArray.length);
    return volume;
  };

  const cleanup = async () => {
    await audioContext.close();
    if (opts.cloneTrack) {
      streamTrack.stop();
    }
  };

  return { calculateVolume, analyser, cleanup };
}

// Format
export function base64ToUint8Array(base64String) {
  return Uint8Array.from(
    globalThis.atob(base64UrlToBase64(base64String)),
    (x) => x.codePointAt(0)
  );
}

function base64UrlToBase64(base64url) {
  return base64url.replaceAll("-", "+").replaceAll("_", "/");
}

const MAX_BLOCK_SIZE = 65_535;

export function uint8ArrayToBase64(array) {
  let base64;

  if (array.length < MAX_BLOCK_SIZE) {
    // Required as `btoa` and `atob` don't properly support Unicode: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    base64 = globalThis.btoa(String.fromCodePoint(...array));
  } else {
    base64 = "";
    for (const value of array) {
      base64 += String.fromCodePoint(value);
    }

    base64 = globalThis.btoa(base64);
  }

  return base64;
}
