export function useUploader() {
  const config = useSanctumConfig();
  const baseUrl = config.baseUrl;
  const token = useCookie('sanctum.token.cookie', { readonly: true });

  return {
    upload(url: string, form: FormData, onProgress: Function) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", baseUrl + url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("accept", "application/json");
        xhr.setRequestHeader("Authorization", `Bearer ${token.value}`);

        xhr.upload.onprogress = function (event) {
          if (!event.lengthComputable) return;
          onProgress((event.loaded / event.total) * 100);
        };
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              resolve(JSON.parse(xhr.responseText));
            } catch (error) {
              resolve(undefined);
            }
          } else {
            reject(new Error(`Upload failed with status: ${xhr.status}`));
          }
        };
        xhr.onerror = function () {
          reject(new Error("Upload failed due to a network error"));
        };
        xhr.send(form);
      });
    },
  };
}
