export class MeetingError extends Error {
  constructor(code, message) {
    super(message || "an error has occured");
    this.name = "MeetingError";
    this.code = code;
  }
}

export class TrackInvalidError extends MeetingError {
  constructor(message) {
    super(20, message ?? "track is invalid");
    this.name = "TrackInvalidError";
  }
}

export class DeviceUnsupportedError extends MeetingError {
  constructor(message) {
    super(21, message ?? "device is unsupported");
    this.name = "DeviceUnsupportedError";
  }
}
