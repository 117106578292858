import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import RemoteAudioTrack from "../track/RemoteAudioTrack";
import RemoteVideoTrack from "../track/RemoteVideoTrack";
import { unpackStreamId, htmlEncode } from "../utils";
import { getToken, getICEServerToken } from "./utils";
import Synthesizer from "../Synthesizer";

export default class AvatarSynthesizer extends Synthesizer {
  constructor() {
    super();

    this.audioContext = null;
    this.peerConnection = null;
    this.synthesizer = null;
    this.lang = "tr-TR";
    this.voice = null;
  }

  get isAvatar() {
    return true;
  }

  async start(config) {
    if (this.state === "connected") {
      console.log(
        "[TalkingAvatar][" + new Date().toISOString() + "] Already connected"
      );
      return Promise.resolve();
    }

    this.setAndEmitConnectionState("connecting");

    const { token, region } = await getToken();

    const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
      token,
      region
    );

    const videoFormat = new SpeechSDK.AvatarVideoFormat();
    videoFormat.setCropRange(
      new SpeechSDK.Coordinate(
        config.videoCropTopLeftX,
        config.videoCropTopLeftY
      ),
      new SpeechSDK.Coordinate(
        config.videoCropBottomRightX,
        config.videoCropBottomRightY
      )
    );

    // Avatar Config
    const avatarConfig = new SpeechSDK.AvatarConfig(
      config.talkingAvatarCharacter,
      config.talkingAvatarStyle,
      videoFormat
    );
    avatarConfig.customized = config.customizedAvatar;
    avatarConfig.backgroundColor = config.backgroundColor;
    avatarConfig.backgroundImage = config.backgroundImage;

    const avatarSynthesizer = new SpeechSDK.AvatarSynthesizer(
      speechConfig,
      avatarConfig
    );

    const iceServerDetails = await getICEServerToken();

    // Setup WebRTC
    const peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: iceServerDetails.Urls,
          username: iceServerDetails.Username,
          credential: iceServerDetails.Password,
        },
      ],
    });

    peerConnection.ontrack = this.onTrack.bind(this);
    peerConnection.ondatachannel = this.onDataChannel.bind(this);
    peerConnection.createDataChannel("eventChannel");
    peerConnection.onconnectionstatechange =
      this.onConnectionStateChange.bind(this);
    peerConnection.oniceconnectionstatechange =
      this.onIceConnectionStateChange.bind(this);
    peerConnection.onsignalingstatechange =
      this.onSignalingStateChange.bind(this);
    peerConnection.addTransceiver("video", { direction: "sendrecv" });
    peerConnection.addTransceiver("audio", { direction: "sendrecv" });

    // Start the avatar
    avatarSynthesizer
      .startAvatarAsync(peerConnection)
      .then(r => {
        if (r.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          console.log(
            `[${new Date().toISOString()}] Avatar started. Result ID: ${
              r.resultId
            }`
          );

          this.error = undefined;
          this.setAndEmitConnectionState("connected");
          this.emit("connected");
        } else {
          console.log(
            `[${new Date().toISOString()}] Unable to start avatar. Result ID: ${
              r.resultId
            }`
          );
          if (r.reason === SpeechSDK.ResultReason.Canceled) {
            const cancellationDetails =
              SpeechSDK.CancellationDetails.fromResult(r);
            console.log(cancellationDetails.errorDetails);
            // log(`Unable to start avatar: ${cancellationDetails.errorDetails}`);
          }
          // this.connectionError = r;
          this.error = r;
          this.emit("error", r);
          this.setAndEmitConnectionState("disconnected");
        }
      })
      .catch(error => {
        console.log(
          `[${new Date().toISOString()}] Avatar failed to start. Error: ${error}`
        );
        this.error = error;
        this.emit("error", error);
        this.setAndEmitConnectionState("disconnected");
      });

    this.synthesizer = avatarSynthesizer;
    this.peerConnection = peerConnection;
    this.lang = config?.lang;
    this.voice = config?.voice;
  }

  async stop() {
    if (this.state === "disconnected") {
      return;
    }

    try {
      if (this.synthesizer) {
        await this.synthesizer.stopAvatarAsync();
        this.synthesizer = null;
      }

      this.peerConnection = null;
    } finally {
      this.setAndEmitConnectionState("disconnected");
      this.emit("disconnected");
    }
  }

  onTrack(event) {
    const mediaTrack = event.track;
    const stream = event.streams[0];
    const receiver = event.receiver;

    const parts = unpackStreamId(stream.id);
    const participantSid = parts[0];
    let streamId = parts[1];
    let trackId = mediaTrack.id;

    if (streamId && streamId.startsWith("TR")) trackId = streamId;

    const isVideo = mediaTrack.kind === "video";
    let track;
    if (isVideo) {
      track = new RemoteVideoTrack(mediaTrack, trackId, receiver);
    } else {
      track = new RemoteAudioTrack(
        mediaTrack,
        trackId,
        receiver,
        this.audioContext
      );
    }
    track.setMediaStream(stream);
    track.start();

    super.setTrack(track);
  }

  onDataChannel(event) {
    const dataChannel = event.channel;
    dataChannel.onmessage = e => {
      const webRTCEvent = JSON.parse(e.data);
      this.emit("dataChannelMessage", webRTCEvent);
      // toplantinin sonladiğini anlamak için
      // if (webRTCEvent.event.eventType === "EVENT_TYPE_TURN_END") {
      //   this.stopSession();
      // }
      // Idle
      // webRTCEvent.event.eventType === "EVENT_TYPE_SWITCH_TO_IDLE"
      // console.log(
      //   "[" + new Date().toISOString() + "] WebRTC event received: " + e.data
      // );
    };
  }

  onIceConnectionStateChange(e) {
    // this.emit("iceConnectionStateChange", this.peerConnection.iceConnectionState);
  }

  onConnectionStateChange(e) {
    // this.emit("connectionState", this.peerConnection.connectionState);
  }

  onSignalingStateChange(e) {
    // this.emit("signalingState", this.peerConnection.signalingState);
  }

  speak(speechData, endingSilenceMs = 0) {
    console.log(
      "[AI-SPEAK][" + new Date().toISOString() + "]",
      speechData.text,
      "IS SPEAKING:",
      this.isSpeaking,
      this.speechQueue.length
    );
    if (this.isSpeaking) {
      this.speechQueue.push(speechData);
      return;
    }

    this.speakNext(speechData, endingSilenceMs);
  }

  speakNext(speechData, endingSilenceMs = 0) {
    let { voice, text, lang } = speechData;
    voice = voice || this.voice;
    lang = lang || this.lang || "tr-TR";

    let ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='${lang}'><voice name='${voice}'><mstts:leadingsilence-exact value='0'/>${htmlEncode(
      text
    )}</voice></speak>`;
    if (endingSilenceMs > 0) {
      ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='${lang}'><voice name='${voice}'><mstts:leadingsilence-exact value='0'/>${htmlEncode(
        text
      )}<break time='${endingSilenceMs}ms' /></voice></speak>`;
    }

    this.setIsSpeaking(true);
    console.log("[AI-SPEAKING-START][" + new Date().toISOString() + "]", text);
    this.synthesizer
      .speakSsmlAsync(ssml)
      .then(result => {
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log(
            "[AI-SPEAKING-COMPLETE][" + new Date().toISOString() + "]",
            text
          );
        } else {
          console.log(
            "[AI-SPEAKING-ERROR][" + new Date().toISOString() + "]",
            text
          );
        }

        if (this.speechQueue.length > 0) {
          this.speakNext(this.speechQueue.shift());
        } else {
          this.setIsSpeaking(false);
        }

        this.emit("speechCompleted", speechData);
      })
      .catch(error => {
        console.log(
          "[AI-SPEAKING-ERROR-CATCH][" + new Date().toISOString() + "]",
          text,
          error,
          this.speechQueue.length
        );

        if (this.speechQueue.length > 0) {
          this.speakNext(this.speechQueue.shift());
        } else {
          this.setIsSpeaking(false);
        }
      });
  }

  stopSpeaking() {
    const skippedTextIds = this.speechQueue.map(s => s?.id).filter(id => id);

    let lastSentence = this.speechQueue.pop();
    console.log(
      "[AI-SPEAKING-STOPING][" + new Date().toISOString() + "]",
      lastSentence?.text
    );
    this.speechQueue = [];
    return this.synthesizer
      .stopSpeakingAsync()
      .then(() => {
        this.setIsSpeaking(false);
        console.log(
          "[AI-SPEAKING-STOPPED][" + new Date().toISOString() + "]",
          lastSentence?.text
        );
        return skippedTextIds;
      })
      .catch(error => {
        console.log(
          "[AI-SPEAKING-STOP-ERROR][" + new Date().toISOString() + "]"
        );
      });
  }
}
