let tokenCache = null;
let iceServerTokenCache = null;

export const getToken = async () => {
  if (tokenCache) return tokenCache;

  const client = useSanctumClient();
  tokenCache = client("/api/azure/speech-token", {
    method: "POST",
  });

  return tokenCache;
};

export const getICEServerToken = async () => {
  if (iceServerTokenCache) return iceServerTokenCache;

  const client = useSanctumClient();
  iceServerTokenCache = client("/api/azure/ice-server-token", {
    method: "POST",
  });

  return iceServerTokenCache;
};
